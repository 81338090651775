import { render, staticRenderFns } from "./Logistics.vue?vue&type=template&id=141e4358&scoped=true&"
import script from "./Logistics.vue?vue&type=script&lang=js&"
export * from "./Logistics.vue?vue&type=script&lang=js&"
import style0 from "./Logistics.vue?vue&type=style&index=0&id=141e4358&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "141e4358",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCarousel,VCarouselItem,VCol,VRow})
