<template>
  <div id="healthcare">
    <v-carousel
      interval="4000"
      hide-delimiters
      :show-arrows="false"
      height="550"
    >
      <div class="container_">
        <v-carousel-item src="../../assets/images/Trailer.png" class="image">
          <v-row class="fill-height" align="center" justify="center">
            <div class="overlay">
              <v-col
                :style="
                  $vuetify.breakpoint.xs
                    ? 'padding:0 3rem'
                    : 'padding:0 5rem 6rem'
                "
              >
                <p class="white--text mb-8 head">
                  Logistics
                </p>
                <h1 class="font-weight-bold white--text">
                  Preserving the integrity of <br />
                  agricultural commodities.
                </h1>
              </v-col>
            </div>
          </v-row>
        </v-carousel-item>
      </div>
    </v-carousel>
    <div>
      <v-col class="d-flex justify-center pt-12">
        <h4 class="text--center">
          To meet the last mile need for refrigerated transport services,
          logistics companies would need to invest in special vehicles and
          infrastructures, racking up high procurement and maintenance costs.
        </h4>
      </v-col>
      <v-row
        :style="
          $vuetify.breakpoint.xs
            ? 'overflow: hidden; padding:3rem 1rem'
            : 'padding: 4rem 0 4rem 4rem;overflow: hidden;'
        "
      >
        <v-col cols="12" sm="6" :class="$vuetify.breakpoint.mdUp ? 'pa-8' : ''">
          <h2 class="pb-8">
            Condition monitoring of products <br />
            for the supply chain.
          </h2>
          <p class="small-p">
            The supply chain for cold chain products requires an adequate
            monitoring solution during transit to prevent product wastage and
            loss. <br />
            <br />
            With Gricd IoT enabled solution helps to monitor
            temperature-sensitive product storage areas continuously during the
            entire journey by reporting temperature and location, eliminating
            the need for additional expensive tracking systems. This helps to
            make sure you are always aware of your product quality, compliance,
            and freshness. <br />
            <br />
            The monitoring system sends instant notifications of temperature
            excursions and off-route travel. The solution helps in distributing
            products safely with full compliance with all regulations and
            standards.
          </p>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          class=" dashboard"
          :class="$vuetify.breakpoint.xs ? 'pl-3' : 'pl-16'"
          ><img src="../../assets/images/Rectangle 1185@3x.png" alt=""
        /></v-col>
      </v-row>
      <!-- <div class="img-size" v-if="$vuetify.breakpoint.smAndUp">
        <img width="100%" src="../../assets/images/Group 1432@3x.png" alt="" />
      </div> -->
      <!-- apparatus equipment section -->
      <div class="py-12" style="background: rgba(242, 245, 247, 0.46);">
        <div class="top-apparatus text-center">
          <h6>The Problem</h6>
          <h2 class="py-6">Unique challenges for logistic compnaies</h2>
        </div>

        <v-row class="px-12 py-5" style="justify-content:space-evenly">
          <div class="apparatus-card" v-for="(m, i) in icon" :key="i">
            <img
              :src="m.img"
              style="height: 4rem;max-width: 4rem;"
              alt=""
              class="mb-4"
            />
            <p class="p1">{{ m.p1 }}</p>
            <p class="p2">{{ m.p2 }}</p>
            <p class=" text-center">
              {{ m.content }}
            </p>
          </div>
        </v-row>
      </div>

      <v-row
        :style="$vuetify.breakpoint.xs ? 'padding:4rem 1rem' : 'padding:4rem'"
      >
        <v-col cols="12" sm="6" class="prod-container">
          <h2>Why Use Gricd Cold Boxes?</h2>
          <p>
            IoT enabled 15 liter active cooling box to maintain stable internal
            temperature of up to -20° C for up to 24 hours.
          </p>
          <img src="../../assets/images/mote-50 1 (1).png" alt="" width="65%" />
        </v-col>
        <v-col cols="12" sm="6" class="right_">
          <img src="../../assets/images/Group 1402.png" alt="" />
          <div>
            <div class="right-div">
              <h5>Save more on coldchain</h5>
              <p>
                Logistics companies can provide their customers with
                refrigerated transport services by latching Gricd boxes onto
                their standard vehicles without the need to invest in special
                vehicles.
              </p>
            </div>
            <div class="right-div">
              <h5>Access to market share</h5>
              <p class="btm-p">
                Access to new markets and market share. There is a large cold
                chain market that needs to be reached. You can breach that gap
                with our cold boxes.
              </p>
            </div>
            <div class="right-div">
              <h5>Trackable system</h5>
              <p>
                Ability to move premium commodity. We offer an IoT enabled,
                efficient and trackable system of conveying items that are
                dependent on cooling.
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import ic1 from '../../assets/about icon/Group 1419 (1).png';
import ic2 from '../../assets/about icon/Group 1419 (2).png';
import ic3 from '../../assets/about icon/Group 1421 (4).png';

export default {
  name: 'Logistics',
  data: () => ({
    icon: [
      {
        img: ic1,
        p1: 'Insufficient Market Share',
        // p2: 'Plasma Sample',
        content:
          'Logistics companies may be unable to access new markets to enable them significantly grow their market share.',
      },
      {
        img: ic2,
        p1: 'High Procurement Cost',
        // p2: 'Blood Sample',
        content:
          'Logistics companies invest in high cost of acquiring special vehicles and equipment.',
      },
      {
        img: ic3,
        p1: 'Expensive Maintenance',
        // p2: 'Vaccine vials',
        content:
          'Special vehicles and cold trucks are expensive for businesses to maintain.',
      },
    ],
  }),
  metaInfo() {
    return {
      script: [
        {
          type: 'application/ld+json',
          json: {
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: [
              {
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://gricd.com/',
              },
              {
                '@type': 'ListItem',
                position: 2,
                name: 'Cold Chain Application',
                item: 'https://gricd.com/cold-chain-application/logistics',
              },
            ],
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#healthcare {
  .img-bcgrd {
    height: 50rem;
    width: 100%;
  }
  background: #fdfeff;
  h4 {
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 180%;
    text-align: center;
    color: #c69d6d;
    width: 50%;
    @media (max-width: 767px) {
      width: 100%;
      text-align: left;
    }
  }
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 0.9rem;
    color: #141414;
  }
  h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 140%;
    color: #212529;
    @media (max-width: 1024px) {
      font-size: 30px;
    }
  }
  .right_ {
    display: flex;
    column-gap: 2rem;
    padding: 0 5rem;
    img {
      height: 27rem;
    }
    @media (max-width: 1024px) {
      padding: 3rem 1rem;
      column-gap: 1rem;
      img {
        height: 22rem;
      }
    }
  }
  h5 {
    font-style: normal;
    font-weight: bold;
    font-size: 1rem;
    padding-bottom: 1rem;
    color: #212529;
  }
  .right-div p {
    margin-bottom: 24%;
    @media (max-width: 1024px) {
      margin-bottom: 1.5rem;
    }
  }
  .prod-container {
    padding: 0.7rem 9rem 0.7rem 4rem;
    h2 {
      padding-bottom: 2rem;
    }
    p {
      padding-bottom: 3rem;
    }

    @media (max-width: 767px) {
      padding: 0.7rem 1rem;
      p {
        padding-bottom: 1rem;
      }
    }
  }
  .btm-p {
    margin-bottom: 19% !important;
    @media (max-width: 1024px) {
      margin-bottom: 4.9rem !important;
    }
  }
  .small-p {
    @media (max-width: 1024px) {
      // font-size: 12px;
    }
  }
  .dashboard {
    display: flex;
    flex-flow: row;
    align-items: center;
    img {
      height: 32rem;
      background: #ffffff;
      box-shadow: 2px 2px 0px rgba(255, 255, 255, 0.2),
        -2px -2px 0px rgba(255, 255, 255, 0.6);
      border-radius: 30px 0 0 30px;
      border: solid 5px #edf3ff;
    }
  }
  .img-size {
    // padding: 0 10rem;
    background: rgba(242, 245, 247, 0.46);
    @media (max-width: 769px) {
      padding: 0 5rem;
    }
  }
  p.head {
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    letter-spacing: 1.1px;
    color: #141414;
  }

  @media (max-width: 1290px) and (min-width: 770px) {
    .right-div p {
      margin-bottom: 1.4rem !important;
    }

    .prod-container {
      padding: 0.7rem 3rem 0.7rem 3rem;
    }
    .btm-p {
      margin-bottom: 5rem !important;
    }
    h1 {
      font-size: 2.4rem;
    }
    p.head {
      font-style: normal;
      font-weight: 500;
      font-size: 1.4rem;
      letter-spacing: 1.1px;
      color: #141414;
    }
    .right_ {
      img {
        height: 18rem;
        transform: rotate(180deg);
        width: auto;
      }
    }
  }

  .container_ {
    position: relative;
  }

  .overlay {
    position: absolute;
    bottom: 0rem;
    background: black;
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    transition: 0.5s ease;
    opacity: 1;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    h2 {
      color: white;
    }
  }

  .container_ .overlay {
    opacity: 1;
  }

  .apparatus-card {
    width: 18rem;
    // border: solid;
    height: 15rem;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
  h6 {
    font-size: 1rem;
    color: #28a84a;
    font-weight: 500;
  }
  .p1 {
    font-size: 1rem;
    font-weight: 500;
  }
  .p2 {
    font-size: 1rem;
    font-weight: 500;
    color: #28a84a;
  }
}
</style>
